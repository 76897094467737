import { useContext } from "react";
import "./stories.scss";
import { AuthContext } from "../../context/authContext";
//import { useQuery } from "@tanstack/react-query";
//import { makeRequest } from "../../axios";

const Stories = () => {
  const { currentUser } = useContext(AuthContext);

  /*const { isLoading, error, data } = useQuery(["stories"], () =>
    makeRequest.get("/stories").then((res) => {
      return res.data;
    })
  );*/

  //TODO Add story using react-query mutations and use upload function.

  /*{error
    ? "Something went wrong"
    : isLoading
    ? "loading"
    : data.map((story) => (
        <div className="story" key={story.id}>
          <img src={story.img} alt="" />
          <span>{story.name}</span>
        </div>
      ))}*/

  return (
    <div className="stories">
      <div className="story">
        <img src={"/upload/" + currentUser.profilePic} alt="" />
        <span>{currentUser.name}</span>
        <button>+</button>
      </div>
      
    </div>
  );
};

export default Stories;
